import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  Flex,
  Box,
  H1,
  H2,
  Body,
  PrimaryButton,
  SecondaryOutlinedButton,
  FAIcon,
  H4,
  Text,
} from '@fivehealth/botero';
import { faEnvelope, faMobileAlt } from '@fortawesome/pro-regular-svg-icons';
import Bg from '@/assets/Bg.svg';
import logoLarge from '@/assets/logo-large.svg';
import parkwayLogoImg from '@/assets/parkway-logo.png';
import { useTranslation } from 'react-i18next';
import { AppConfig, getAuthRedirectURL } from '@/config/app.config';
import { useAuthContext } from '@/context';
import { ROUTES } from '@/routes/Router';
import { useCookies } from 'react-cookie';
import theme from '@/theme/theme';
import styled from 'styled-components';
import ContactSupportModal from '@/components/Modals/ContactSupportModal';
import { HELP_SUPPORT } from '@/config/constants/misc.constants';
import { NavigateOrderStateType } from '@/helpers/types/ui.types';
import { nullTextParser } from '@/helpers/utils';

export const NavTextItem = styled(H4)`
  cursor: pointer;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
  &:hover,
  &:focus {
    color: #256bf6;
  }
`;

function LoginRedirect() {
  const { t } = useTranslation();
  const loginUrl = getAuthRedirectURL();
  const {
    authState: { isAuthenticated },
  } = useAuthContext();
  const location = useLocation();
  const navState = location.state as NavigateOrderStateType;
  const cookieName = AppConfig.STORAGE.COOKIE_TOKEN_KEY;
  const [cookies] = useCookies([cookieName]);
  const cookieToken = nullTextParser(cookies?.[cookieName]);
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);
  const isResponsiveMin = useMediaQuery({ query: '(max-width: 1240px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 940px)' });

  if (isAuthenticated || cookieToken) {
    return <Navigate to={ROUTES.ORDERS.ROOT} state={{ path: location.pathname }} />;
  }

  const NavMenu = () => (
    <Flex
      width={isResponsiveMin ? 300 : 400}
      minWidth={300}
      flexDirection="row"
      justifyContent="space-between">
      <NavTextItem
        fontSize={isResponsiveMin ? 12 : 16}
        onClick={() => {
          setContactUsModalOpen(true);
        }}>
        {t('Contact Parkway')}
      </NavTextItem>
      <NavTextItem
        fontSize={isResponsiveMin ? 12 : 16}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          window.open(HELP_SUPPORT.BOTMD_SUPPORT_LINK_PRE_LOGIN, '_blank').focus();
        }}>
        {t('Login Support')}
      </NavTextItem>
      <NavTextItem
        fontSize={isResponsiveMin ? 12 : 16}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          window.open(HELP_SUPPORT.PARKWAY_HELP_GUIDE_URL, '_blank').focus();
        }}>
        {t('Help Guide')}
      </NavTextItem>
    </Flex>
  );

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        width="45%"
        style={{
          position: 'absolute',
          left: 45,
          top: 20,
        }}>
        <Box>
          <img src={logoLarge} height={40} style={{ cursor: 'pointer' }} alt="" />
        </Box>
        <Flex>
          <NavMenu />
        </Flex>
      </Flex>
      <Flex width="100%" height="100vh">
        <ContactSupportModal open={contactUsModalOpen} onClose={() => setContactUsModalOpen(false)} />

        <Box flex={1}>
          <Flex
            height="100%"
            boxSizing="border-box"
            maxWidth={450}
            m="auto"
            width="calc(100% - 64px)"
            py={8}
            alignItems="center"
            flexDirection="column"
            flex={1}
            justifyContent="center">
            <Flex flexDirection="column">
              <Body>
                <img
                  src={parkwayLogoImg}
                  style={{ cursor: 'pointer' }}
                  width={210}
                  height="auto"
                  alt="Parkway"
                />
              </Body>
              <H1 mb={2}>{t('Log into Parkway Radiology')}</H1>
              <Body small mb="28px">
                {t('Select your login method')}
              </Body>
              {navState && (
                <Body mt={-1} mb={2} color="danger" textAlign="center">
                  {navState.error?.message === 'Staff profile not found.' ? (
                    <Box>
                      <Text as="span" color="danger">{`No user account found. Submit a request `}</Text>
                      <Text
                        as="span"
                        color="primary"
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-expect-error
                          window?.open(HELP_SUPPORT.BOTMD_SUPPORT_LINK_PRE_LOGIN, '_blank').focus();
                        }}>
                        here
                      </Text>
                    </Box>
                  ) : (
                    navState.error?.message
                  )}
                </Body>
              )}

              <PrimaryButton
                borderRadius={8}
                fsdf
                mb={2}
                style={{ padding: 8 }}
                onClick={() => {
                  window.location.replace(loginUrl.sms);
                }}>
                <Flex flexDirection="row" fontSize={14}>
                  <FAIcon icon={faMobileAlt} style={{ color: 'white', paddingRight: 5, width: 16 }} />{' '}
                  {t('Sign in via Mobile number')}
                </Flex>
              </PrimaryButton>
              <SecondaryOutlinedButton
                borderRadius={8}
                mb={5}
                onClick={() => {
                  window.location.replace(loginUrl.email);
                }}>
                <Flex flexDirection="row" fontSize={14}>
                  <FAIcon
                    icon={faEnvelope}
                    style={{ color: theme.colors.darkestShade, paddingRight: 5, width: 15 }}
                  />{' '}
                  <Text as="span" color="darkestShade">
                    {t('Sign in via Email address')}
                  </Text>
                </Flex>
              </SecondaryOutlinedButton>
              <Body small textAlign="center">
                {t('Don’t have an account yet?')}{' '}
                <Body
                  small
                  as="span"
                  color="primary"
                  cursor="pointer"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    window?.open(HELP_SUPPORT.BOTMD_SUPPORT_LINK_PRE_LOGIN, '_blank').focus();
                  }}>
                  {t('Sign Up')}
                </Body>
              </Body>
            </Flex>
            <Body fontSize={12} color="darkShade" textAlign="center">
              {t(
                'By registering, you agree to the Terms & Conditions of Use of the Service as well as the Bot MD Privacy Policy'
              )}
            </Body>
          </Flex>
        </Box>
        {!isMobile && (
          <Flex
            flex={1}
            justifyContent="center"
            alignItems="center"
            style={{
              background: `url(${Bg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}>
            <Box color="emptyShade" maxWidth={380} width="calc(100% - 64px)">
              <H2 color="emptyShade">{t('Welcome to Parkway’s Radiology Digital Ordering Platform')}</H2>
              <br />
              <Body color="emptyShade">
                {t(
                  'Schedule Radiology exams at any Parkway Radiology center across Singapore, 24/7 in real-time.  Receive instant notifications once your patient’s radiology scans and report are ready.'
                )}
              </Body>
            </Box>
          </Flex>
        )}
      </Flex>
    </>
  );
}

export default React.memo(LoginRedirect);
