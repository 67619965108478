import MessageBanner from '@/components/Banner/MessageBanner';
import { DATE_FORMAT_TXT } from '@/config/constants/misc.constants';
import { Box, Text, theme } from '@fivehealth/botero';
import moment from 'moment';

export const TemporaryDowtimeBanner = () => {
  // TODO: This is how we schedule a down time. Please remove this component later to properly take out the banner
  const currDate = moment(moment().format(DATE_FORMAT_TXT.YYYY_MM_DD_DASH_TIME_1));
  const endDate = moment('2024-08-22T06:00:00+08:00'); // Aug 22, 2024 6am
  const isHideBanner = currDate.isAfter(endDate);

  return !isHideBanner ? (
    <MessageBanner
      open
      sticky
      type="error"
      hideClose
      content={
        <Box>
          <Text as="span" color={theme.colors.danger}>
            Parkway Radiology ordering will be unavailable from 21 August 2024 at 10pm SGT until 22 August 2024
            at 6am SGT. Please call the Call Center for any Radiology Exam bookings at{' '}
            <Text as="span" color={theme.colors.danger} fontWeight={600}>
              +65 6388 4333
            </Text>
            . We apologize for any inconvenience.
          </Text>
        </Box>
      }
      bannerProps={{ mb: 2, mt: -2 }}
    />
  ) : null;
};
